<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb1rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">UserStatsTooltje</div>
      </div>      
    </div>
    
    <div v-if="logState.accessLevel === 10" class="columns is-centered is-gapless is-marginless">
        <div class="column is-mobile is-narrow mt2rem mb2rem">
          <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
        </div>
      </div>
    
    
    <div>
      <div class="columns is-multiline is-centered is-gapless is-marginless">        
        <b-field label="postcode|afstand|professionlist" class="column is-8 is-offset-2">
          <b-input maxlength="100000" v-model="filterSettings" type="textarea" custom-class="inputheight1" @input="clearResults"></b-input>
        </b-field>        
      </div>
      
      <div class="columns is-multiline is-centered is-gapless is-marginless">        
        <b-field class="column is-8 is-offset-2">
          <b-button @click="Send">Versturen</b-button>  
        </b-field>        
      </div>
      
    </div>
    
    <div class="columns is-centered is-gapless is-marginless is-multiline">
      <h4 class="column is-mobile mt2rem is-12" style="text-align:center">Results:</h4>
      <b-table
        v-if="userstats"
        class="column is-mobile is-narrow mb2rem is-6 is-offset-3"
        
        :data="userstats.allResults"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        :hoverable="true"

        :loading="false"
        :focusable="true"

        :header-checkable="false"
        :mobile-cards="true"                
        checkbox-position="left"
        >
        
        <template >
          <b-table-column field="postcode" label="postcode" width="100" numeric v-slot="props" centered>  
            {{ props.row.postcode }}
          </b-table-column>
          <b-table-column field="postcodeDetails" label="gevonden postcode" width="100" numeric v-slot="props" centered>  
            {{ props.row.postcodeDetails ? props.row.postcodeDetails[0].formattedAddress : ' ??' }}
          </b-table-column>
          <b-table-column field="distance" label="afstand" width="100" v-slot="props" centered>
            {{ props.row.distance }}km
          </b-table-column>
          <b-table-column field="proffessionNames" label="proffessions" width="100" v-slot="props" centered>  
            {{ props.row.proffessionNames ? props.row.proffessionNames.map((prof) => {return `${prof.id}-${prof.name}`}).join(` & `) : '??' }}
          </b-table-column>
          <b-table-column field="profCount" label="aantal" width="100" v-slot="props" centered>  
            {{ props.row.profCount }}
          </b-table-column>
        </template>               
      </b-table>
      
    </div>
    <div v-if="userstats" class="columns is-centered is-gapless is-marginless is-multiline">
      <div class="column is-10 is-offset-2">Unique emails merged: {{ userstats.mergedCount }}</div>
      
      <b-button v-show="logState.accessLevel === 10 && isUnlocked && userstats.mergedCount > 0" @click="download" class="column is-mobile is-narrow mt2rem mb2rem is-6 is-offset-3">
        Download CSV
      </b-button>
    </div>
        
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';

import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT } from '@/config/app.js';
import { mapState } from 'vuex';

// const EmailValidator = require("email-validator");

export default {
  name: "UserStatsTooltje", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      LockStatus : {
        unlocked: false,
        Locktime: 0,
      },
      logState : {
                      isLoggedIn  : null,
                      updateTime  : null, //time last update
                      accessLevel : 0,    
                      user_id     : null,
                      email       : null,
                      name        : null, 
                    },
      
      filterSettings      : "example:\n1012AB | 30km | 49,50\n5469pg | 25km | 50",
      
      userstats: null,
      
    }
  },
  
  computed: {
      ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
  },
  watch: {
    loggedInState() {
      this.logState = this.$store.state.authentication.loggedInState;
      // console.info("LoggedInState Changed:", this.logState);
      
      if (this.logState.accessLevel === 10) {
        // console.info(`updating lock status???`);
        this.UpdateLockStatus();
      }
    },
  },
  
  async mounted(){
    
    
    this.twoFaStatus = await this.$store.dispatch('twofa/getStatus', null);   
    if (this.twoFaStatus.status !== "active") {
      this.$buefy.dialog.alert({
          title: `2FA is required`,
          message: `2FA is required`,
          onConfirm: () => {
            this.$router.push({ path: '/profile' }).catch(() => {});
          }
      })
      return;
    }
    
    this.$store.dispatch('authentication/updateLoginState', null);
    
  },
  
  methods: {
      
    // async UpdateLockStatus() {
    //   const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
    //   // console.info(`LockStatus:`, LockStatus);
    //   this.SetUnlockStatus(LockStatus);
    // },   
    clearResults(){
      this.userstats = null
    },
    
    async Send() {
      await this.ShowPageLoading(5000)      
      
      this.userstats = null
      
      let response = await axios_api.post(`${API_ROOT}/userstats/database/GetUserToolStats`, {
        filterSettings: `${this.filterSettings}`
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get user tool data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.userstats = response.data.payload
      
      
      console.info(`response:`, response)
      this.HidePageLoading()
    },
    
    async download(){
      await this.ShowPageLoading(5000) 
      let response = await axios_api.post(`${API_ROOT}/userstats/database/GetUserToolStats/download`, {
        filterSettings: `${this.filterSettings}`
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get user tool data2', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      
      let downloadbody = `email\n${response.data.payload.emailList.join(`\n`)}` 
      this.downloadBlob(`DELETE_postcode_prof_download_${new Date().toISOString()}.csv`, downloadbody)
      
      // const fileName = encodeURIComponent(`DELETE_postcode_prof_download_${new Date().toISOString()}.csv`);
      // console.info(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}&fname=${fileName}`);
      // window.open(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}&fname=${fileName}`)
    
    },
    
    downloadBlob(filename, text) {
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async UpdateLockStatus() {
      if (this.logState.accessLevel !== 10)  return false;
      // console.info(`-UpdateLockStatus??`);
      
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },
    
    async Unlock() {
      const _this = this;
      console.info(`-Unlock??`);
      if (this.isUnlocked) {
        await _this.$store.dispatch('databaseactions/DB_ClearLockStatus', { });
        await _this.UpdateLockStatus();
        return;
      }
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      if (LockStatus.unlocked === true) {
        // this.GetTableStructureData();
      }
      this.CheckLockStatus();
    },
    
    
    CheckLockStatus(){
      if (this.logState.accessLevel < 10) return false;
      // console.info(`Checking lock status???`);
      
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
      }
    },
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 200px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
  

</style>